import { useState } from 'react';
import './ZJAdminLoginPage.css'
import axios from 'axios';
import ZJAdminDataManager from '../ZJAdminDataManager/ZJAdminDataManager';

interface ZJAdminLoginPageProps {
    show: boolean;
    finishAddFun: Function
}

const ZJAdminLoginPage: React.FC<ZJAdminLoginPageProps> = ({ show, finishAddFun }) => {
    const [userPhone, setUserPhone] = useState("")
    const [userPassword, setUserPassword] = useState("")

    const sendLoginRequest = function () {
        if (userPhone != null
            && userPhone.length > 0
            && userPassword != null
            && userPassword.length > 0) {
            let data = { "phone": userPhone, "password": userPassword };
            axios
                .post("/api/admin_login/login", data)
                .then(
                    response => {
                        if (response.status === 200) {
                            if (response.data && response.data.status && response.data.status === "0") {
                                return response.data.session
                            }
                        }
                        return null
                    }, error => {

                    }
                ).then(token => {
                    if (token && token.length > 0) {
                        ZJAdminDataManager.saveAdminToken(token)
                        if (finishAddFun) {
                            finishAddFun()
                        }
                    } else {
                        alert("登录失败，请重新尝试登录")
                    }
                })
        } else {
            alert("请输入用户名和密码")
        }
    }
    return (
        <div id="zj_login_page"
            style={{ display: (show ? "block" : "none") }}>
            {/* <button onClick={()=> {
                if (finishAddFun) {
                    finishAddFun()
                }
            }}>登录页面</button> */}
            <div>
                <br />
                手机号
                <input onChange={(event) => {
                    let value = event.target.value;
                    if (value) {
                        setUserPhone(value)
                    }
                }} /><br />
                密码
                <input onChange={(event) => {
                    let value = event.target.value;
                    if (value) {
                        setUserPassword(value)
                    }
                }} />
            </div>
            <button
                onClick={() => {
                    sendLoginRequest()
                }}>登录</button>
        </div>

    )
}

export default ZJAdminLoginPage