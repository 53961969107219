import LCRouteManger from '../../../LCRouter/LCRouteManager';
import LCArticleContentPage from '../../LCArticleContentPage/LCArticleContentPage';
import ZJPageFrame from '../../ZJPageFrame/ZJPageFrame';
import './LCCPlusPlusLessonsPage.css'

const LCCPlusPlusLessonsPage = () => {
    return (
        <div className="main_content_container">
            <ZJPageFrame index={1}>
                <div>
                    <div id="lc_python_lesson_header_line_div">
                        <div id='lz_python_category_header'>
                            <a href={LCRouteManger.LCAllLessonsPageUrlPath}>所有课程</a>
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;&gt;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            <a href={LCRouteManger.LCCPlusPlusLessonsPageUrlPath}>C++课程</a>
                        </div>
                    </div>
                    <LCArticleContentPage componentArticleID='4' />
                </div>
            </ZJPageFrame>
        </div>);
}

export default LCCPlusPlusLessonsPage