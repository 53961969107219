import { Flip, toast, ToastContainer } from "react-toastify";
import LCRouteManger from "../../../LCRouter/LCRouteManager";
import ZJDeviceChecker from "../../Tools/ZJDeviceChecker";
import ZJPageFrame from "../../ZJPageFrame/ZJPageFrame";
import './LCAllLessonsPage.css'
import LCUnableCover from "../../Components/LCUnableCover/LCUnableCover";

const LCAllLessonsPage = function () {
  const innerDivClassName = ZJDeviceChecker.horizontalDevice() ? "each_lesson_div_computor" : "each_lesson_div_phone"

  const typingPracticeHref = "/typingpractice"
  const duringDevelopingTip = () =>
    toast.warn('课程还在开发中~', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      transition: Flip,
    });
  return (
    <div className="main_content_container">
      <ZJPageFrame
        index={1}>
        <div id="all_class_page">
          <div
            className={innerDivClassName + " typing_practice_div"}>
            <div className="lc_text_bg"></div>
            <a href={typingPracticeHref}>打字练习</a>
          </div>
          <div
            className={innerDivClassName + " scratch_lession_div"}>
            <div className="lc_text_bg"></div>
            <a href={LCRouteManger.LCScratchLessonsPageUrlPath}>scratch课程</a>
          </div>
          <div
            className={innerDivClassName}
            onClick={duringDevelopingTip}>
            <div className="lc_text_bg"></div>
            <a href={LCRouteManger.LCPythonLessonsPageUrlPath}>Python课程</a>
          </div>
          <div className={innerDivClassName}
            onClick={duringDevelopingTip}>
            <LCUnableCover />
            <div className="lc_text_bg"></div>
            <a href={LCRouteManger.LCCPlusPlusLessonsPageUrlPath}>C++课程</a>
          </div>
          <div className={innerDivClassName}
            onClick={duringDevelopingTip}>
            <div className="lc_text_bg"></div>
            其他文章资料(人工智能)等
          </div>
          <div className={innerDivClassName + " last_lesson_div_place_holder"}></div>
        </div>
      </ZJPageFrame>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        transition={Flip}
      />
    </div>
  )
}

export default LCAllLessonsPage;