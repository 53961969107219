import LCUserTokenManager from "../LCData/LCUserTokenManager";
import LCRouteManger from "../../LCRouter/LCRouteManager";

class LCErrorHandler {
    static tokenInvalidStatus = "-100" // 和服务端相对应的，需要清除token，同时跳转登录页，重新登录
    static handleError( response ) {
        if (response != null && response.data != null && response.data.status === LCErrorHandler.tokenInvalidStatus ) {
            this.clearSessionAndJumpToLoginInPage();
        }
    }

    // 清空session并重新跳转到登录页
    static clearSessionAndJumpToLoginInPage() {
        LCUserTokenManager.removeUserToken()
            let loginPageUrl = window.location.protocol + "//" + window.location.host + LCRouteManger.LCUserLoginPageUrlPath + "?last_page_url=";
            let currentUrl = window.location.href
            if (currentUrl) {
                currentUrl = btoa(encodeURI(currentUrl));
            }
            if (currentUrl) {
                loginPageUrl = loginPageUrl + currentUrl;
            }
            // 跳转到登录页面
            window.location.href = loginPageUrl;
    }
}

export default LCErrorHandler