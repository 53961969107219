import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import LCCPlusPlusEditor from "../Components/LCCPlusPlusEditor/LCCPlusPlusEditor";
import { useLocation } from 'react-router-dom';
import LCPythonEditor from "../Components/LCPythonEditor/LCPythonEditor";
import './LCCodePlayground.css'
import LCCodePerformTool from "../Tools/LCCodePerformTool";
import LCClassResourceFileContentGetter from "../Tools/LCClassResourceFileContentGetter";
import LCUserTokenManager from "../LCData/LCUserTokenManager";
import LCUserAccountHelper from "../LCUserAccount/LCUserAccountHelper/LCUserAccountHelper";

/**
 * 
 关于参数
 下面的json base64之后放到path里面也就是 ?info= 后面即可
 具体
 language 可选参数 c++ 或者 python
 file file那么是文件的远程储存地址
 hightlight要高亮显示的代码行数
{
"language":"c++",
"file":"",
"highlight":[1, 2,3]
}

langugage说明语言类型，
file:"":如果是文件，那么获取到文件然后下载；
highlight_lines 要重点突出的行数

上述JSON整体base64一下，放到path里面然后这个文件获取,content需要整体base64,之后再次base64

python代码helloworld
http://localhost:3000/code_playground?info=ewoibGFuZ3VhZ2UiOiJweXRob24iLAoiZmlsZSI6Imh0dHBzOi8vaG9uZ3hpbnlvdXppLmNvbS9hcGkvbWFuYWdlX2NsYXNzX3Jlc291cmNlL2dldF9jbGFzc190ZXh0X2NvbnRlbnQ/Y2xhc3NfbmFtZT0wMDJfMDAxXzAwMV8wMDFfMDAxXzAwMF9oZWxsb193b3JsZC5weXRob24iLAoiaGlnaGxpZ2h0IjpbMl0KfQ

刚开始执行失败，过一会儿就好了，不知道为啥
http://localhost:3000/code_playground?info=ewoibGFuZ3VhZ2UiOiJjKysiLAoiZmlsZSI6Imh0dHBzOi8vaG9uZ3hpbnlvdXppLmNvbS9hcGkvbWFuYWdlX2NsYXNzX3Jlc291cmNlL2dldF9jbGFzc190ZXh0X2NvbnRlbnQ/Y2xhc3NfbmFtZT0wMDNfMDAxXzAwMV8wMDFfMDAxXzAwMF9oZWxsb193b3JsZC5jcHAiLAoiaGlnaGxpZ2h0IjpbMl0KfQ
 */
const LCCodePlayground = function () {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const info = query.get('info');

    let currentCode = useRef("")
    let language: string = ""
    let file: string = ""
    if (info != null) {
        const infoString = atob(info);
        const infoObj = JSON.parse(infoString)
        let tempLanuage = infoObj["language"]
        if (tempLanuage) {
            language = tempLanuage
        }
        let tempFile = infoObj["file"]
        if (tempFile) {
            file = tempFile
        }
        let tempHighlightLines = infoObj["highlight"]
        if (tempHighlightLines) {
            // highlightLineNumbers = tempHighlightLines
        }
    }

    const checkLoginState = useMemo(() => {
        return () => {
            if (!LCUserTokenManager.getUserToken()) {
                LCUserAccountHelper.jumpToLoginPageWithLastPageUrl();
            }
        }
    }, [])


    const initDiv =
        (<div>
            内容加载中,请稍等
        </div>);

    const [contentDiv, setContentDiv] = useState(initDiv);
    const [performanceSuccessResult, setPerformanceSuccessResult] = useState<string>("");
    const [performanceFailResult, setPerformanceFailResult] = useState<string>("");
    const [performanceState, setPerformanceState] = useState<string>("");


    const codeFinishPerform = function (success: boolean, result: string) {
        if (success) {
            setPerformanceSuccessResult(result)
            setPerformanceFailResult("")
        } else {
            setPerformanceSuccessResult("")
            setPerformanceFailResult(result)
        }
    }

    // useCallBack是为了在useEffect中使用
    const cplusplusEditorComponent = useCallback((codeValue: string) => {
        let desDiv = <div>init editor</div>
        if (language === "c++") {
            desDiv = (<LCCPlusPlusEditor codeValue={codeValue} updateCallBack={
                (value: string) => {
                    currentCode.current = value;
                }
            } />)
        } else if (language === "python") {
            desDiv = (<LCPythonEditor codeValue={codeValue} updateCallBack={
                (value: string) => {
                    currentCode.current = value;
                }
            } />)
        }
        return desDiv
    }, [language]);

    const failContentsDiv = useMemo(() => {
        return (
            <div className="lc_playground_no_content_div">没有获取到文件内容</div>
        );
    }, []);

    const requestFileContent = useMemo(() => {
        return () => {
            LCClassResourceFileContentGetter.instance.requestFileContent(file, (success: boolean, content: string) => {
                if (success) {
                    let theCodeValue = ""
                    if (content) {
                        theCodeValue = atob(content)
                        currentCode.current = theCodeValue
                    }
                    const desContents = cplusplusEditorComponent(theCodeValue);
                    setContentDiv(desContents)
                } else {
                    setContentDiv(failContentsDiv)
                }
            })
        }
    }, [failContentsDiv, cplusplusEditorComponent, file]);
    useEffect(() => {
        checkLoginState();
        if (file.length > 0) {
            requestFileContent()
        }
    }, [file, requestFileContent, checkLoginState]);

    const performCode = function () {
        setPerformanceState("代码执行中")
        setPerformanceSuccessResult("")
        setPerformanceFailResult("")
        LCCodePerformTool.performCode(language, currentCode.current, (statusCode: string, content: string) => {
            let theResult = ""
            let success = false
            if (content) {
                try {
                    content = atob(content)
                } catch (error) {

                }
            }
            if (statusCode === "0") {
                success = true
                theResult = "代码执行成功:" + content
            } else {
                success = false
                // 发生错误，错误提示
                theResult = "发生错误:" + content
            }
            setPerformanceState("")
            codeFinishPerform(success, theResult)
        })
    }
    return (
        <div className="main_content_container">
            <div>
                {contentDiv}
            </div>
            <div>
                <button onClick={() => {
                    performCode()
                }}>点击这里</button><br />
                <div className="lc_playground_perform_state_div">{performanceState}</div>
                <div className="lc_playground_success_result_div">{performanceSuccessResult}</div>
                <div className="lc_playground_fail_result_div">{performanceFailResult}</div>
            </div>
        </div>
    )

}

export default LCCodePlayground