import { useLocation } from 'react-router-dom';
import './LCUserLoginMiddlePage.css'
import { useState } from 'react';

const LCUserLoginMiddlePage = () => {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const lastPageUrlParam = query.get('last_page_url');
    const lastPageUrl = "/user_login_page?last_page_url=" + lastPageUrlParam ;
    const [time, setTime] = useState(10);
    let theInterval = setInterval(() => {
        let tempTime = time - 1;
        if (tempTime <= 0) {
            setTime(tempTime);
            clearInterval(theInterval)
            window.location.href = lastPageUrl;
        } else {
            setTime(tempTime);
        }
    }, 1000);
    return (
        <div>
            <div id="to_login_bg_div">
                <div id="to_login_front_bg_div">
                    <div className="to_login_front_bg_div_inner_div">
                        <a href={lastPageUrl}>无法使用当前功能，需要先登录!</a>
                        <a href={lastPageUrl}>即将自动跳转至登录页</a>
                        <a className='just_to_login_page_left_time' href={lastPageUrl}>{time}</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LCUserLoginMiddlePage