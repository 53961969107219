

export default class LCRouteManger {
    // homePage
    public static AppHomePageUrlPath = "/homepage"
    public static LCMinePageUrlPath = '/mine_page'
    
    // admin
    public static ZJArticleAdminCenterUrlPath = '/admin_center_page'

    // playground
    public static LCCodePlaygroundUrlPath = '/code_playground'

    // lessons page
    public static LCAllLessonsPageUrlPath = '/all_class_page'
    public static LCScratchLessonsPageUrlPath = "/scratch_lessons_page"
    public static LCCPlusPlusLessonsPageUrlPath = "/cpp_lessons_page"
    public static LCPythonLessonsPageUrlPath = "/python_lessons_page"

    // user login
    public static LCUserLoginPageUrlPath = "/user_login_page"
    public static LCUserSignUpPageUrlPath = '/user_sign_up_page'
    public static LCUserLoginMiddlePageUrlPath = 'login_middle_page'

    // article
    public static LCArticleContentPagePath = 'article_content_page'
    public static LCArticleContentNormalDetailPagePath = 'article_content_normal_detail_page'

    // test
    public static LCTestPageUrlPath = '/test_page'
}