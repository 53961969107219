import ZJPageFrame from "../ZJPageFrame/ZJPageFrame"
import LCArticleContentPage from "./LCArticleContentPage"
import './LCArticleContentNormalDetailPage.css'

const LCArticleContentNormalDetailPage = () => {
    let params = new URLSearchParams(window.location.search);
    let articleTitle = params.get("article_title")
    return (
        <ZJPageFrame index={0}>
            <div>
                <div className="article_content_normal_detail_page_title">{articleTitle}</div>
                <LCArticleContentPage />
            </div>
        </ZJPageFrame>
    )
}

export default LCArticleContentNormalDetailPage