import './ZJDouyinVideoPlayer.css'

interface ZJDouyinVideoPlayerProps {
    douyinVideoID: string
    douyinURL: string
    douyinWidth: number
    douyinHeight: number
    children?: React.ReactNode
    closeCallBack: Function
}
const ZJDouyinVideoPlayer: React.FC<ZJDouyinVideoPlayerProps> = ({ douyinURL, douyinVideoID, douyinWidth, douyinHeight, closeCallBack }) => {
    const deviceWidth = window.innerWidth
    const deviceHeight = window.innerHeight

    let desWidth = deviceWidth
    let desHeight = deviceHeight
    let tempWidth = douyinWidth
    let tempHeight = douyinHeight
    let tempDesWidth = tempHeight * deviceWidth / deviceHeight
    let tempDesHeight = tempWidth * deviceHeight / deviceWidth
    if (tempDesHeight < deviceHeight) {
        desWidth = deviceWidth
        desHeight = tempDesHeight
    } else if (tempDesWidth < deviceWidth) {
        desWidth = tempWidth
        desHeight = deviceHeight
    }

    return (
        <div id="zj_douyin_player">
            <div id='zj_douyin_player_top_button_bg'></div>
            <div id="zj_douyin_player_content">
                <div id="zj_douyin_iframe_container">
                    <iframe id="player_inner_frame" width={desWidth} height={desHeight} src={douyinURL} referrerPolicy="unsafe-url" allowFullScreen title="douyin_video"></iframe>
                </div>
                <div id="player_inner_buttons_div" onClick={() => {
                    if (closeCallBack) {
                        closeCallBack()
                    }
                }}>
                    <div id="player_inner_buttons_div_bg"></div>
                    <span id="close_icon">{"X"}</span>
                    <span id="close_tip">{"关闭当前视频"}</span>
                </div>


            </div>
        </div>)
}

export default ZJDouyinVideoPlayer