import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AppHomePage from "../UserInterface/AppHomePage/AppHomePage";
import LCAllLessonsPage from "../UserInterface/LCLessonsPage/LCAllLessonsPage/LCAllLessonsPage";
import LCMinePage from "../UserInterface/LCMinePage/LCMinePage";
import LCCodePlayground from "../UserInterface/LCCodePlayground/LCCodePlayground";
import ZJArticleAdminCenter from "../AdminPage/AdminArticle/ZJArticleAdminCenter/ZJArticleAdminCenter";
import LCUserLoginPage from "../UserInterface/LCUserAccount/LCUserLoginPage/LCUserLoginPage";
import LCRouteManger from "./LCRouteManager";
import LCTestPage from "../Test/LCTestPage";
import LCUserSignUpPage from "../UserInterface/LCUserAccount/LCUserSignUpPage/LCUserSignUpPage";
import LCArticleContentPage from "../UserInterface/LCArticleContentPage/LCArticleContentPage";
import LCScratchLessonsPage from "../UserInterface/LCLessonsPage/LCScratchLessonsPage/LCScratchLessonsPage";
import LCCPlusPlusLessonsPage from "../UserInterface/LCLessonsPage/LCCPlusPlusLessonsPage/LCCPlusPlusLessonsPage";
import LCPythonLessonsPage from "../UserInterface/LCLessonsPage/LCPythonLessonsPage/LCPythonLessonsPage";
import LCUserLoginMiddlePage from "../UserInterface/LCUserAccount/LCUserLoginMiddlePage/LCUserLoginMiddlePage";
import LCArticleContentNormalDetailPage from "../UserInterface/LCArticleContentPage/LCArticleContentNormalDetailPage";

const LCBrowserRouter = function () {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<AppHomePage />} />
                <Route path={LCRouteManger.AppHomePageUrlPath} element={<AppHomePage />} />
                <Route path={LCRouteManger.LCAllLessonsPageUrlPath} element={<LCAllLessonsPage />} />
                <Route path={LCRouteManger.LCMinePageUrlPath} element={<LCMinePage />} />
                <Route path={LCRouteManger.LCCodePlaygroundUrlPath} element={<LCCodePlayground />} />
                <Route path={LCRouteManger.ZJArticleAdminCenterUrlPath} element={<ZJArticleAdminCenter />} />

                {/* login page */}
                <Route path={LCRouteManger.LCUserLoginPageUrlPath} element={<LCUserLoginPage/>} />
                <Route path={LCRouteManger.LCUserLoginMiddlePageUrlPath} element={<LCUserLoginMiddlePage />} />
                <Route path={LCRouteManger.LCUserSignUpPageUrlPath} element={<LCUserSignUpPage />} />

                {/* test page */}
                <Route path={LCRouteManger.LCTestPageUrlPath} element={<LCTestPage />} />
                

                {/* articles */}
                <Route path={LCRouteManger.LCArticleContentPagePath} element={<LCArticleContentPage />} />
                <Route path={LCRouteManger.LCArticleContentNormalDetailPagePath} element={<LCArticleContentNormalDetailPage />} />

                {/* lessons page */}
                <Route path={LCRouteManger.LCScratchLessonsPageUrlPath} element={<LCScratchLessonsPage />} />
                <Route path={LCRouteManger.LCCPlusPlusLessonsPageUrlPath} element={<LCCPlusPlusLessonsPage />} />
                <Route path={LCRouteManger.LCPythonLessonsPageUrlPath} element={<LCPythonLessonsPage />} />

            </Routes>
        </BrowserRouter>
    );
}
export default LCBrowserRouter;