import { useState } from "react";
import ZJTextEditor from "../../../Components/ZJTextEditor/ZJTextEditor";
import './ZJUpdateArticle.css'
import axios from "axios";
import ZJAdminDataManager from "../ZJAdminDataManager/ZJAdminDataManager";
import ZJImageEditor from "../../../Components/ZJImageEditor/ZJImageEditor";
import ZJImageFileToBase64Tool from "../../../Components/ZJImageEditor/ZJImageFileToBase64Tool";
import LCFileDownloader from "../../../UserInterface/Tools/LCFileDownloader";
import ZJTextEditorTool from "../../../Components/ZJTextEditor/ZJTextEditorTool";

interface ZJUpdateArticleProps {
    show: boolean;
    initArticleID: string;
    initTitle: string,
    initContent: string,
    finishUpdateFun: Function
}

const ZJUpdateArticle: React.FC<ZJUpdateArticleProps> = ({ show, initArticleID, initTitle, initContent, finishUpdateFun }) => {
    const [articleTitle, setArticleTitle] = useState(initTitle)
    const [articleContent, setArticleContent] = useState(initContent)
    const articleAllImages = ZJTextEditorTool.getAllArticleImageUrls(initContent)

    const submitCurrentArticle = () => {
        if (articleTitle == null || articleTitle.length === 0) {
            alert("标题和文章不能为空")
            return
        }
        let data = {
            id: initArticleID,
            title: articleTitle,
            content: articleContent,
            token: ZJAdminDataManager.getAdminToken()
        }
        axios.post("/api/article/update_article", data)
            .then(response => {
                if (response.status === 200) {
                    if (response.data && response.data.status && response.data.status === "0") {
                        return true
                    }
                }
                return false
            }, error => {
                return false
            })
            .then(success => {
                if (success) {
                    if (finishUpdateFun) {
                        finishUpdateFun()
                    }
                } else {
                    alert("更新失败，重新尝试")
                }
            })
    }
    return (
        <div id="zj_article_update_page"
            style={{ display: (show ? "block" : "none") }}>
            <div>
                修改文章
            </div>
            <div>
                <input defaultValue={initTitle} onChange={(event) => {
                    if (event != null
                        && event.target != null
                        && event.target.value != null) {
                        setArticleTitle(event.target.value)
                    }
                }} />
            </div>

            <div>image处理器</div>
            <ZJImageEditor
                previewImageUrlArray={articleAllImages}
                uploadAction={(file: File, finishBlock: Function) => {
                    ZJImageFileToBase64Tool(file)
                        .then(base64String => {
                            LCFileDownloader.instance.uploadImage(base64String as string, file.name, (url: string) => {
                                if (finishBlock != null) {
                                    finishBlock(url);
                                }
                            })
                        }, error => {
                            return ""
                        })
                }}>
            </ZJImageEditor>
            <br />


            <ZJTextEditor
                contentString={initContent}
                previewMode={"1"}
                onChangeCallBack={(value: string) => {
                    setArticleContent(value)
                }} />
            <button onClick={() => {
                submitCurrentArticle()
            }}>确认修改并提交</button>
        </div>
    )
}

export default ZJUpdateArticle;