import axios from "axios"
import LCUserTokenManager from "../LCData/LCUserTokenManager"

class LCClassResourceFileContentGetter {
    static instance = new LCClassResourceFileContentGetter()
    
    requestFileContent(url: string, callBack: Function) {
        let desUrl = url
        // 调试时的时候有问题，无法跨域，所以这里直接替换
        desUrl = desUrl.replace("https://hongxinyouzi.com", "")
        desUrl = desUrl.replace("http://localhost:3000", "")
        desUrl = desUrl.replace("http://127.0.0.1:3000", "")
        axios.post(desUrl, {token: LCUserTokenManager.getUserToken()})
        .then(response => {
            if (response.status === 200) {
                if (response.data && 
                    response.data.status && 
                    response.data.status === "0") {
                    let jsonString = response.data.data
                    let obj = JSON.parse(jsonString)
                    if (obj) {
                        return obj['content']
                    }
                }
            }
            return null
        }, error => {
            return null
        }).then(content => {
            if (callBack) {
                callBack(content !== null && content.length > 0, content)
            }
        })
    }
    
}

export default LCClassResourceFileContentGetter
