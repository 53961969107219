import LCRouteManger from '../../../LCRouter/LCRouteManager';
import LCArticleContentPage from '../../LCArticleContentPage/LCArticleContentPage';
import ZJPageFrame from '../../ZJPageFrame/ZJPageFrame';
import './LCPythonLessonsPage.css'

const LCPythonLessonsPage = () => {
    return (
        <div className="main_content_container">
            <ZJPageFrame index={1}>
                <div>
                    <div id="lc_python_lesson_header_line_div">
                        <div id='lz_python_category_header'>
                            <a href={LCRouteManger.LCAllLessonsPageUrlPath}>所有课程</a>
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;&gt;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            <a href={LCRouteManger.LCPythonLessonsPageUrlPath}>Python课程</a>
                        </div>
                    </div>
                    <LCArticleContentPage componentArticleID='3' />
                </div>
            </ZJPageFrame>
        </div>);
}

export default LCPythonLessonsPage