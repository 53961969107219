import LCRouteManger from "../LCRouter/LCRouteManager"
import LCUserTokenManager from "../UserInterface/LCData/LCUserTokenManager";
import LCUserAccountComponent from "../UserInterface/LCUserAccount/LCUserAccountComponent/LCUserAccountComponent";
import LCUserLoginButton from "../UserInterface/LCUserAccount/LCUserLoginButton/LCUserLoginButton"
import LCUserLogoutButton from "../UserInterface/LCUserAccount/LCUserLogoutButton/LCUserLogoutButton";
import ZJImageEditor from "../Components/ZJImageEditor/ZJImageEditor";
import ZJImageFileToBase64Tool from "../Components/ZJImageEditor/ZJImageFileToBase64Tool";
import LCFileDownloader from "../UserInterface/Tools/LCFileDownloader";
import { useEffect, useState } from "react";
import ZJDouyinVideoPlayer from "../Components/ZJDouyinVideoPlayer/ZJDouyinVideoPlayer";

const LCTestPage = () => {
    // const loadLoginStateDiv = () => {
    //     let array = [];
    //     let login = LCUserTokenManager.userLoginState()
    //     if (login) {
    //         array.push(
    //             <LCUserLogoutButton />
    //         )
    //     } else {
    //         array.push(<LCUserLoginButton currentUrl={LCRouteManger.LCTestPageUrlPath} />)
    //     }
    //     return array;
    // }
    // return (
    //     <div>
    //         <LCUserAccountComponent />

    //     </div>
    // )




    // return (
    //     <div className="main_content_container">
    //         <ZJImageEditor
    //         previewImageUrlArray={["http://127.0.0.1:9000/myimagebucket/1733921509170_9QTUVp.png"]}
    //             uploadAction={(file: File, finishBlock: Function) => {
    //                 ZJImageFileToBase64Tool(file)
    //                     .then(base64String => {
    //                         LCFileDownloader.instance.uploadImage(base64String as string, file.name, (url: string) => {
    //                             if (finishBlock != null) {
    //                                 finishBlock(url);
    //                             }
    //                         })
    //                     }, error => {
    //                         return ""
    //                     })
    //             }}>
    //         </ZJImageEditor>
    //     </div>
    // )

    const [openVideo, setOpenVideo] = useState(false)


    return (
        <div id="main_content_conainer">
            <div>
                <button onClick={() => {
                    setOpenVideo(true)
                }}>打开视频</button>
            </div>
            {
                openVideo ? 
                (<ZJDouyinVideoPlayer
                        douyinURL="https://open.douyin.com/player/video?vid=7432677182540909836&autoplay=1"
                        douyinVideoID="7432677182540909836"
                        douyinWidth={1920}
                        douyinHeight={1080} closeCallBack={() => {
                            setOpenVideo(false)
                        }}/>) : 
                ("")
            }
        </div>
    )
}

export default LCTestPage