import './LCUnableCover.css'

interface LCUnableCoverProps {
    children?: React.ReactNode
}

const LCUnableCover:React.FC<LCUnableCoverProps> = ({children}) => {
    return (
        <div className="lc_unable_cover_div">
            {children ? children : ""}
        </div>
    )
}

export default LCUnableCover