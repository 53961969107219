
import ZJPageFrame from "../ZJPageFrame/ZJPageFrame";
import AppHomeCarousel from "./AppHomeCarousel";

const AppHomePage = function () {
  return (
    <div className="main_content_container">
      <ZJPageFrame
        index={0}>
        <div>
          <AppHomeCarousel />
          <div>
            <a
              href="/article_content_normal_detail_page?article_id=5&article_title=为什么学习编程">
              为什么学习编程</a>
          </div>
          <div>
            <a
              href="/article_content_normal_detail_page?article_id=6&article_title=编程是什么">
              编程是什么</a>
          </div>
          <div>
            <a
              href="/article_content_normal_detail_page?article_id=6&article_title=如何学习编程">
              如何学习编程</a>
          </div>
          <div>
            <a
              href="/article_content_normal_detail_page?article_id=7&article_title=本站课程介绍">
              本站课程介绍</a>
          </div>
          <div>
            <a
              href="/article_content_normal_detail_page?article_id=8&article_title=如何开启本站的学习之路">
              如何开启本站的学习之路</a>
          </div>
        </div>
      </ZJPageFrame>
    </div>

  )
}

export default AppHomePage;
